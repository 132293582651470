<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";
import Multiselect from "vue-multiselect";
import { bankTypeEnum } from "@/components/enums.js";
import TourTable from "../../../components/reservation/TourTable";
import HotelTable from "../../../components/reservation/HotelTable";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  page: {
    title: "Gelir Ekle",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect, HotelTable, TourTable, },
  data() {
    return {
      title: "Gelir Ekle",
      items: [
        {
          text: "RTS",
          href: "/",
        },
        {
          text: "Gelir Listesi",
          href: "/revenue"
        },
        {
          text: "Gelir Ekle",
          active: true,
        },
      ],
      // Value
      type: this.$route.query.type === "reservation" ? {
        id: 1,
        name: "Rezervasyon"
      } : "",
      value: {
        id: 0,
      },
      balance: "",
      currency: "",
      date: "",
      bank_type: "",
      pay_type: "",
      comment: this.$route.query.type === "reservation" ? this.values?.confirme_number : "", 
      // Value End
      payType: [],
      bankTypeEnum,
      values: [],
      datascurrency: [],
      expenseLabel: this.$route.query.type === "reservation" ? "Gelir: Rezervasyon" : "Gider:",
      expenseType: [],
      employee: [],
      reservation: [],
      isApproval: false,
      isBug: false,
      isItems: false,
    };
  },
  created() {
    if (this.$route.query.id) {
      this.getReservation()
    }
    this.getAllCurrency(), this.getAllBanks(), this.getAllExpense();
  },
  watch: {
    type(value) {
      if (value.name == "Personel") {
        this.expenseLabel = "Gelir: Personel"
        this.getData("/employee/getAll", "values");
      }
      if (value.name == "Acenta") {
        this.expenseLabel = "Gelir: Acenta"
        this.getData("/agency/getAll", "values");
      }
      if (value.name == "Cari") {
        this.expenseLabel = "Gider: Cari"
        this.getData("/current-account/getAll", "values");
      }
    }
  },
  methods: {
    getAllCurrency() {
      this.getData("/exchange/getAll", "datascurrency");
    },
    getAllBanks() {
      this.getData("/banks/getAll", "payType");
    },
    getAllExpense() {
      this.getData("/incomeexpensetype/getAll/1", "expenseType");
    },
    getReservation() {
      const itemId = this.$route.query.id;
      const api_url = process.env.VUE_APP_BASEURL + `/reservation/${itemId}`;
      axios
        .get(api_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.reservation = response.data?.data
          const responseData = response.data?.data
          // Acenta bilgisi
          const agencyData = [
            {
              id: responseData.agency.id,
              type: 1, // Acenta tipi
              reservation_id: responseData.id,
              name: "Acenta: " + responseData.agency.company_name,
              confirme_number: responseData?.hotel?.confirme_number ? "Confirme: " + responseData?.hotel?.confirme_number : ""
            }
          ]

          this.values = agencyData;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    submit() {
      const api_url = process.env.VUE_APP_BASEURL + "/revenue";
      const data = {
        type: this.type.id,
        revenue_value: JSON.stringify(this.value),
        balance: this.balance,
        currency: this.currency.id,
        date: this.date,
        bank_type: this.bank_type.value,
        pay_type: this.pay_type.id,
        comment: this.comment,
      }
      axios
        .post(api_url, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          if(this.$route.query.type === 'reservation') {
            this.$refs.modalApproval.show();
            this.isItems = true;
          } else {
            this.$router.push("/revenue");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getData(endpoint, dataKey, callback = null) {
      axios
        .get(process.env.VUE_APP_BASEURL + endpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this[dataKey] = response.data?.data;
          if (callback) {
            callback(response);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    approval(){
      const api_url = process.env.VUE_APP_BASEURL + "/reservation/update/" + this.$route.query.id;
      const requestData = {
        status:2
      };

      axios
        .put(api_url, requestData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.isApproval = true;
          // 2 saniyelik gecikme koyarak yönlendirme yap
          setTimeout(() => {    
            this.$router.push("/revenue");
          }, 4000); // 2000 milisaniye = 2 saniye
        })
        .catch((error) => {
          this.isBug = true;
          console.log(error);
          setTimeout(() => {    
            this.$router.push("/revenue");
          }, 4000); // 2000 milisaniye = 2 saniye
        })
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="submit">
              <div class="row">

                <div class="col-md-4">
                  <div class="form-group mb-3">
                    <label for="brand_name">Gelir Türü</label>
                    <multiselect v-model="type" :options="expenseType" label="name">
                    </multiselect>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group mb-3">
                    <label for="brand_name">{{ expenseLabel }}</label>
                    <multiselect v-model="value" :options="values" label="name">
                    </multiselect>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group mb-3">
                    <label for="brand_name">Ödeme Tarihi</label>
                    <input type="date" v-model="date" class="form-control">
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group mb-3">
                    <label for="brand_name">Tutar</label>
                    <input v-model="balance" type="double" class="form-control" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group mb-3">
                    <label for="brand_name">Kur</label>
                    <multiselect v-model="currency" :options="datascurrency" label="name">
                    </multiselect>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group mb-3">
                    <label for="brand_name">Ödeme Türü</label>
                    <multiselect v-model="bank_type" :options="bankTypeEnum" label="text">
                    </multiselect>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group mb-3">
                    <label for="brand_name">Ödeme Yöntemi</label>
                    <multiselect v-model="pay_type" :options="payType" label="label">
                    </multiselect>
                  </div>
                </div>

                <div class="col-md-8">
                  <div class="form-group mb-3">
                    <label for="brand_name">Açıklama</label>
                    <input v-model="comment" type="text" class="form-control" />
                  </div>
                </div>

              </div>
              <div class="d-flex justify-content-end align-items-center">
                <button type="submit" class="btn btn-primary px-4">Kaydet</button>
              </div>
            </form>

          </div>
        </div>
        <div class="mt-3" v-if="this.$route.query.type === 'reservation'">
          <aside v-if="reservation.reservations.length > 0">
            <HotelTable :reservations="reservation.reservations" :editProps="editProps" />
          </aside>

          <aside v-if="reservation.tourRoutes.length > 0">
            <TourTable :tourRoutes="reservation.tourRoutes" :editTourProps="editTourProps" />
          </aside>
        </div>
        <b-modal id="modal-approval" centered ref="modalApproval" title="Bakiye Transfer" title-class="font-18" size="sm" hide-footer>
          <b-alert v-if="isItems" show variant="success">Başarıyla işlendi.</b-alert>
          <b-alert v-if="isApproval" show variant="success">Başarıyla güncellendi. Yönlendiriliyorsunuz.</b-alert>
          <b-alert v-if="isBug" show variant="danger">Hata oluştu. Lütfen manuel olarak rezervasyonu güncelleyiniz.</b-alert>
          <form @submit.prevent="approval">
            <div class="row">
              <div class="col-12">
                <button type="submit" class="btn btn-success">Acenta Ödemesini Onayla</button>
              </div>
            </div>
          </form>
        </b-modal>
      </div>
    </div>
  </Layout>
</template>